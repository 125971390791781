import React from 'react';
import { Col, Row, Container } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import footerLogo from '../../img/footer-logo.svg';
import { LinkWrapper } from '../../ui-components/LinkWrapper/LinkWrapper';
import logo24 from '../../img/logo.svg';
import logoacs from '../../img/logo-footer.png';
import styles from './Footer.module.css';

const Footer: React.FC = () => {
  const { t } = useTranslation();

  return (
    <footer>
      <Container>
        <Row>
          <Col lg={3}>
            <a className={styles.footerLogo} target="_blank" rel="noopener noreferrer" href="https://www.ctmalder.ch">
              <img className="responsive-img" src={footerLogo} alt="footer-logo" />
            </a>

            <ul className={styles.socialLinkList}>
              <li>
                <a
                  className={styles.socialLinkItem}
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://www.facebook.com/ctmalder/"
                >
                  <i className="icon-facebook" />
                </a>
              </li>
              <li>
                <a
                  className={styles.socialLinkItem}
                  rel="noopener noreferrer"
                  target="_blank"
                  href="https://www.instagram.com/ctm_alder_ag/?hl=de"
                >
                  <i className="icon-instagram" />
                </a>
              </li>
              <li>
                <a
                  className={styles.socialLinkItem}
                  rel="noopener noreferrer"
                  target="_blank"
                  href={t('info.emailhref')}
                >
                  <i className="icon-email" />
                </a>
              </li>
              <li>
                <a className={styles.socialLinkItem} rel="noopener noreferrer" href={t('info.telhref')}>
                  <i className="icon-whatsapp" />
                </a>
              </li>
              <li>
                <LinkWrapper to="/contacts" className={styles.socialLinkItem}>
                  <i className="icon-support" />
                </LinkWrapper>
              </li>
            </ul>
          </Col>
          <Col lg={5}>
            <div className={styles.footerTextWrapper}>
              <div className={styles.footerText}>
                <span className={styles.footerTextItem}>{t('footer.CTMAlderAG')}</span>
                <span className={styles.footerTextItem}>{t('footer.GlutzBlotzheimStrasse3')}</span>
                <span className={styles.footerTextItem}>{t('footer.CH4500Solothurn')}</span>
              </div>
              <div className={styles.footerText}>
                {t('info.tellabel')}
                {' · '}
                {t('footer.web')}
              </div>
              <div className={styles.footerCopyright}>
                {`© ${new Date().getFullYear()} `}
                copyright by CTM Alder AG
                {' | '}
                <LinkWrapper className={styles.footerCopyrightLink} to="/imprint">
                  {t('footer.imprint')}
                </LinkWrapper>
                {' | '}
                <LinkWrapper className={styles.footerCopyrightLink} to="/asa">
                  {t('footer.asa')}
                </LinkWrapper>
                {process.env.REACT_APP_VERSION && ` | ${process.env.REACT_APP_VERSION}`}
              </div>
            </div>
          </Col>
          <Col lg={4}>
            <a className={styles.acs} href="http://www.acs.ch" target="_blank" rel="noopener noreferrer">
              <img src={logoacs} alt="acs.ch" height="80" />
            </a>
            <a href="https://private.routinier24.ch?utm_source=ACS&utm_medium=button&utm_campaign=footer">
              <img src={logo24} alt="routinier24.ch" height="80" />
            </a>
          </Col>
          <Col sm={12}>
            <div className={styles.footerCopyrightMobile}>
              {`© ${new Date().getFullYear()} `}
              copyright by CTM Alder AG
              {' | '}
              <LinkWrapper className={styles.footerCopyrightLink} to="/imprint">
                {t('footer.imprint')}
              </LinkWrapper>
              {' | '}
              <LinkWrapper className={styles.footerCopyrightLink} to="/asa">
                {t('footer.asa')}
              </LinkWrapper>
              {process.env.REACT_APP_VERSION && ` | ${process.env.REACT_APP_VERSION}`}
            </div>
          </Col>
        </Row>
      </Container>
    </footer>
  );
};

export default Footer;
